import React from "react";
import { TProduct } from "../components/productTile/productTile";

const getProductsUrl = "https://api.menue.tim-buecker.de/api/product/complete";
const postProductUrl = "https://api.menue.tim-buecker.de/api/product";

export type TUseProductsReturnValue = {
  productData: TProduct[];
  productDataPending: boolean;
  productChangingPending: boolean;
  changeProduct: (newProduct: TProduct) => void;
};

export default function useProducts(
  getInitialData: boolean
): TUseProductsReturnValue {
  const [productData, setProductData] = React.useState<TProduct[]>([]);
  const [productDataPending, setProductDataPending] =
    React.useState<boolean>(false);
  const [productChangingPending, setProductChangingPending] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (getInitialData) {
      getProducts();
    }
  }, []);

  const getProducts = (): void => {
    setProductDataPending(true);
    fetch(getProductsUrl, { method: "GET" })
      .then((res) => {
        res.json().then((json) => {
          setProductDataPending(false);
          setProductData(json);
        });
      })
      .catch((err) => console.log(err));
  };

  const changeProduct = (newProduct: TProduct): void => {
    setProductChangingPending(true);
    fetch(postProductUrl, { method: "PUT", body: JSON.stringify(newProduct) })
      .then((res) => {
        res.json().then((json) => {
          setProductChangingPending(false);
        });
      })
      .catch((err) => console.log(err));
  };

  return {
    productData,
    productDataPending,
    productChangingPending,
    changeProduct,
  };
}
