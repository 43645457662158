import React from "react";
import "./OrderTile.scss";
import useOrders, { TOrder } from "../../hooks/UseOrders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

export type TOrderTileProps = {
  order: TOrder;
  onOrderChange: (changedOrder: TOrder) => void;
};

function OrderTile(props: TOrderTileProps) {
  const { changeOrder, orderChangingPending } = useOrders(false, undefined);

  React.useEffect(() => {
    if (orderChangingPending === false) props.onOrderChange(props.order);
  }, [orderChangingPending]);

  const onOrderFinishClick = (order: TOrder): void => {
    order.state = "finished";
    changeOrder(order);
  };

  const onOrderCancelClick = (order: TOrder): void => {
    order.state = "canceled";
    changeOrder(order);
  };

  return (
    <div className="OrderTile">
      {orderChangingPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <span className="Id"># {props.order.id}</span>
          <img src={props.order.product.picture} />
          <span className="Quantity">{props.order.quantity}x</span>
          <hr className='SeperatorVertical' />
          <span className="Title">{props.order.product.title}</span>
          <hr className='SeperatorVertical' />
          <span className="Username">{props.order.username}</span>
          <span className="Actions">
            <button
              className="ButtonPrimary error"
              onClick={() => onOrderCancelClick(props.order)}
            >
              <FontAwesomeIcon icon={faCancel} className="MessageBarIcon" />
            </button>
            <button
              className="ButtonPrimary success"
              onClick={() => onOrderFinishClick(props.order)}
            >
              <FontAwesomeIcon icon={faCheck} className="MessageBarIcon" />
            </button>
          </span>
        </>
      )}
    </div>
  );
}

export default OrderTile;
