import React, { ReactElement, useEffect } from "react";
import "./Admin.scss";
import useOrders, { TOrder } from "../../hooks/UseOrders";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import OrderTile from "../../components/OrderTile/OrderTile";
import useProducts from "../../hooks/UseProducts";
import AdminProductTile from "../../components/AdminProductTile/AdminProductTile";

export type TAdminSiteProps = {};

function AdminSite(props: TAdminSiteProps) {
  const [refreshInterval, setRefreshIntervall] = React.useState<number>(30);
  const [filter, setFilter] = React.useState<string>("new");
  const [showState, setShowState] = React.useState<
    "products" | "orders" | string
  >("orders");

  const { orderData, orderDataPending, triggerGetOrders, setOrderData } =
    useOrders(true);

  const { productData, productDataPending, productChangingPending, changeProduct } = useProducts(true);

  useEffect(() => {
    const interval = setInterval(
      () => {
        triggerGetOrders();
      },
      refreshInterval === 0 ? 999999999999 : refreshInterval * 1000
    );

    return () => clearInterval(interval);
  }, [refreshInterval]);

  if (showState === "products")
    return (
      <div className="AdminSite">
        <div className="Header">
          <span className="SiteTitle">Admin Center</span>
          <div className="HeaderNav">
            <select
              className="Dropdown ShowStateDropdown"
              value={showState}
              onChange={(e) => setShowState(e.currentTarget.value)}
            >
              <option value="orders">Bestellungen</option>
              <option value="products">Produkte</option>
            </select>
          </div>
        </div>
        <div className="SiteContent">
          {productDataPending ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="OrderTileList">
                {productData
                  .sort((a, b) => a.id - b.id)
                  .map((product) => (
                    <AdminProductTile
                      product={product}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  else
    return (
      <div className="AdminSite">
        <div className="Header">
          <span className="SiteTitle">Admin Center</span>
          <div className="HeaderNav">
            <select
              className="Dropdown ShowStateDropdown"
              value={showState}
              onChange={(e) => setShowState(e.currentTarget.value)}
            >
              <option value="orders">Bestellungen</option>
              <option value="products">Produkte</option>
            </select>
          </div>
          <div className="HeaderOptions">
            <div className="Filter">
              <label>Filter:</label>
              <select
                className="Dropdown FilterDropdown"
                value={filter}
                onChange={(e) => setFilter(e.currentTarget.value)}
              >
                <option value="new">Neu</option>
                <option value="finished">Fertig</option>
                <option value="canceled">Abgebrochen</option>
              </select>
            </div>

            <div className="RefreshIntervall">
              <label>Refresh (s):</label>
              <input
                className="Dropdown"
                placeholder="seconds"
                type="number"
                value={refreshInterval}
                defaultValue={0}
                onChange={(e) => {
                  setRefreshIntervall(Number.parseInt(e.currentTarget.value));
                }}
              ></input>
            </div>
          </div>
        </div>
        <div className="SiteContent">
          {orderDataPending ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="OrderTileList">
                {orderData
                  .filter((order) => order.state === filter)
                  .sort((a, b) => a.id - b.id)
                  .map((order) => (
                    <OrderTile
                      order={order}
                      onOrderChange={(order) => {
                        var copy = [...orderData];
                        var o = copy.find((o) => o.id === order.id);
                        if (o !== undefined) o = order;
                        setOrderData(copy);
                      }}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
}

export default AdminSite;
