import React, { ReactElement } from "react";
// import logo from './logo.svg';
import "./App.scss";
import OverviewSite from "./sites/overview/OverviewSite";
import { TProduct } from "./components/productTile/productTile";
import ProductInformations from "./sites/productInformations/ProductInformations";
import Dialog from "./components/Dialog/Dialog";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminSite from "./sites/admin/Admin";

var impressum = require("./impressum.js");
var dsgvo = require("./dsgvo.js");


function App() {
  const [renderState, setRenderState] = React.useState<string>("overview");
  const [selectedProduct, setSelectedProduct] = React.useState<
    TProduct | undefined
  >(undefined);

  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string | undefined>(undefined);

  const renderRightState = (): ReactElement => {
    switch (renderState) {
      case "overview":
        return (
          <OverviewSite
            onStateChange={(newState: string) => setRenderState(newState)}
            onProductSelect={(product) => setSelectedProduct(product)}
            username={username}
            onLogout={onLogout}
          />
        );

      case "productInformations":
        return (
          <ProductInformations
            username={username}
            product={selectedProduct}
            onStateChange={(newState: string) => setRenderState(newState)}
          />
        );

      default:
        return <></>;
    }
  };

  const onLogout = (): void => {
    localStorage.removeItem("username");
    setUsername(undefined);
    console.log("logout");
  };

  const onLogin = (): void => {
    if (username) {
      localStorage.setItem("username", username);
      setShowDialog(false);
    }
    console.log("login", username);
  };

  const onStorageLogin = (): void => {
    const user = localStorage.getItem("username");

    console.log(user);

    if (user === null || user === "" || user === undefined) setShowDialog(true);
    else setUsername(user);
  };

  React.useEffect(() => {
    onStorageLogin();
  }, []);

  React.useEffect(() => {
    const user = localStorage.getItem("username");
    if (user === null || username === "") {
      setShowDialog(true);
    }
  }, [username]);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Dialog
                  isShown={showDialog}
                  header={<span>Namenseingabe</span>}
                  content={
                    <div className="RegisterDialogContent">
                      <label>Ihr kompletter Name:</label>
                      <input
                        placeholder="Max Mustermann"
                        value={username}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                      ></input>
                    </div>
                  }
                  footer={
                    <button
                      id="registerDialogCloseButton"
                      className="ButtonPrimary RegisterDialogSaveButton"
                      onClick={() => onLogin()}
                      disabled={username === undefined || username === ""}
                    >
                      Speichern & Bestätigen
                    </button>
                  }
                />
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                {renderRightState()}
              </>
            }
          ></Route>
          <Route path="admin" element={<AdminSite />} />
          <Route
            path="impressum"
            element={<div dangerouslySetInnerHTML={{ __html: impressum }} />}
          />
          <Route path="dsgvo" element={<div className="dsgvo" dangerouslySetInnerHTML={{ __html: dsgvo }} />} />
          <Route path="*" element={<>404 - Page not found!</>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
