import React from "react";
import { TProduct } from "../components/productTile/productTile";

const getOrdersUrl = "https://api.menue.tim-buecker.de/api/order/complete";
const postOrderUrl = "https://api.menue.tim-buecker.de/api/order";

export type TOrder = {
  id: number;
  username: string;
  productId: number;
  quantity: number;
  state: string;
  product: TProduct;
};

export type TOrderInput = {
  username: string;
  productId: number;
  quantity: number;
};

export type TUseProductsReturnValue = {
  orderData: TOrder[];
  orderDataPending: boolean;
  orderCreationPending: boolean;
  orderChangingPending: boolean;
  createOrder: (newOrder: TOrderInput) => void;
  triggerGetOrders: () => void;
  changeOrder: (newOrder: TOrder) => void;
  setOrderData: React.Dispatch<React.SetStateAction<TOrder[]>>;
};

export default function useOrders(
  getInitialData: boolean,
  onOrderCreationSuccess?: () => void,
): TUseProductsReturnValue {
  const [orderData, setOrderData] = React.useState<TOrder[]>([]);
  const [orderDataPending, setOrderDataPending] =
    React.useState<boolean>(false);
  const [orderCreationPending, setOrderCreationPending] =
    React.useState<boolean>(false);
  const [orderChangingPending, setOrderChangingPending] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (getInitialData) {
      getOrders();
    }
  }, []);

  const createOrder = (newOrder: TOrderInput): void => {
    setOrderCreationPending(true);
    fetch(postOrderUrl, { method: "POST", body: JSON.stringify(newOrder) })
      .then((res) => {
        res.json().then((json) => {
          setOrderCreationPending(false);
          if (onOrderCreationSuccess) onOrderCreationSuccess();
        });
      })
      .catch((err) => console.log(err));
  };

  const changeOrder = (newOrder: TOrder): void => {
    setOrderChangingPending(true);
    fetch(postOrderUrl, { method: "PUT", body: JSON.stringify(newOrder) })
      .then((res) => {
        res.json().then((json) => {
          setOrderChangingPending(false);
        });
      })
      .catch((err) => console.log(err));
  };

  const getOrders = (): void => {
    setOrderDataPending(true);
    fetch(getOrdersUrl, { method: "GET" })
      .then((res) => {
        res.json().then((json) => {
          setOrderDataPending(false);
          setOrderData(json);
        });
      })
      .catch((err) => console.log(err));
  };

  const triggerGetOrders = (): void => {
    getOrders();
  };

  return {
    orderData,
    orderDataPending,
    orderCreationPending,
    orderChangingPending,
    setOrderData,
    createOrder,
    triggerGetOrders,
    changeOrder,
  };
}
