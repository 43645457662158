import React from 'react';
import './LoadingSpinner.scss';

export type TLoadingSpinnerProps = {
    
}

function LoadingSpinner(props: TLoadingSpinnerProps) {

    return (
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
}

export default LoadingSpinner;
