import React, { PropsWithChildren, ReactElement } from 'react';
import './Dialog.scss';

export type TDialogProps = {
    header?: ReactElement | ReactElement[];
    content?: ReactElement | ReactElement[];
    footer?: ReactElement | ReactElement[];
    isShown: boolean;
}

function Dialog(props: PropsWithChildren<TDialogProps>) {

    React.useEffect(() => {
        const bodyElem = document.getElementsByTagName("BODY")[0] as HTMLBodyElement;

        console.log(bodyElem)

        if (props.isShown === false)
            bodyElem.style.overflow = "auto";
        else
            bodyElem.style.overflow = "hidden";
    }, [props.isShown])

    return (<>
        <div className='DialogBackdrop' hidden={!props.isShown} />
        <div id="registerDialog" className='Dialog' hidden={!props.isShown}>
            <div className="DialogHeader">
                {props.header}
                <hr className='Seperator' />
            </div>
            <div className="DialogContent">
                {props.content}
            </div>
            <div className="DialogFooter">
                {props.footer}

            </div>
        </div>
    </>
    );
}

export default Dialog;
