import React from "react";
import "./ProductInformations.scss";
import { TProduct } from "../../components/productTile/productTile";
import useOrders from "../../hooks/UseOrders";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export type TProductInformationsProps = {
  username: string | undefined;
  product: TProduct | undefined;
  onStateChange: (newState: string) => void;
};

function ProductInformations(props: TProductInformationsProps) {
  const [quantity, setQuantity] = React.useState<number>(1);
  const [showOrderSuccess, setShowOrderSuccess] =
    React.useState<boolean>(false);

  const onOrderCreationSuccess = () => {
    setShowOrderSuccess(true);
    setTimeout(() => {
      setShowOrderSuccess(false);
      // props.onStateChange("overview");
    }, 4000);
  };

  const { createOrder, orderCreationPending } = useOrders(
    false,
    onOrderCreationSuccess
  );

  const onGetProductClick = () => {
    if (props.product && props.username) {
      createOrder({
        username: props.username,
        productId: props.product.id,
        quantity: quantity,
      });
    }
  };

  console.log(props.product)

  return (
    <div className="ProductInformations">
      <div className="Header">
        <button
          className="ButtonSecondary"
          onClick={() => props.onStateChange("overview")}
          disabled={orderCreationPending}
        >
          Zurück
        </button>
      </div>
      <div className="SiteContent">
        {orderCreationPending ? (
          <LoadingSpinner />
        ) : (
          <div className="InnerContent">
            <img src={props.product?.picture} />
            <div>
              <span className="Title">{props.product?.title}</span>
            </div>
            <div>
              <span className="Description">{props.product?.description}</span>
            </div>
            <div className="Ingredients">
              {props.product?.ingredients === undefined ||
              props.product?.ingredients.length > 0 ? (
                <>
                  <span className="IngredientsTitle">Zutaten:</span>
                  <ul>
                    {props.product?.ingredients.map((i) => (
                      <li>{i.name}</li>
                    ))}
                  </ul>
                </>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {showOrderSuccess ? (
        <div className="MessageBar-Success">
          <FontAwesomeIcon icon={faInfoCircle} className="MessageBarIcon" />
          Ihre Bestellung wurde erfolgreich aufgegeben.
        </div>
      ) : null}
      <div className="Footer">
        {props.product?.inStock === true ? (
          props.product?.canOrdered ? (
            <>
              <span className="QuantityText">Anz:</span>
              <select
                className="Dropdown QuantityDropdown"
                disabled={orderCreationPending}
                onChange={(e) =>
                  setQuantity(Number.parseInt(e.currentTarget.value))
                }
              >
                <option value="1">1x</option>
                <option value="2">2x</option>
                <option value="3">3x</option>
                <option value="4">4x</option>
                <option value="5">5x</option>
                <option value="6">6x</option>
              </select>
              <button
                className="ButtonPrimary GetProductButton"
                disabled={orderCreationPending}
                onClick={() => onGetProductClick()}
              >
                Bestellung aufgeben
              </button>
            </>
          ) : (
            <button className="ButtonPrimary SelfServiceProductButton">
              Selbstbedienung
            </button>
          )
        ) : (
          <button className="ButtonPrimary NotInStockProductButton">
            Nicht verfügbar
          </button>
        )}
      </div>
    </div>
  );
}

export default ProductInformations;
