import React, { ReactElement } from "react";
import "./OverviewSite.scss";
import ProductTile, {
  TProduct,
} from "../../components/productTile/productTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import useProducts from "../../hooks/UseProducts";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";

export type TOverviewSiteProps = {
  onStateChange: (newState: string) => void;
  onProductSelect: (product: TProduct) => void;
  username: string | undefined;
  onLogout: () => void;
};

function OverviewSite(props: TOverviewSiteProps) {
  const [showUserDropdown, setShowUserDropdown] =
    React.useState<boolean>(false);

  const { productData, productDataPending } = useProducts(true);

  const renderProductTiles = (): ReactElement[] | ReactElement => {
    if (productDataPending) return <LoadingSpinner />;

    return productData.map((product) => {
      return (
        <ProductTile
          key={product.id}
          product={product}
          onClick={() => onProductTileClick(product)}
        />
      );
    });
  };

  const onProductTileClick = (product: TProduct): void => {
    props.onStateChange("productInformations");
    props.onProductSelect(product);
  };

  return (
    <div className="OverviewSite">
      <div className="Header">
        <div
          className="UserNameDisplay"
          id="userNameDisplay"
          onMouseOver={() => setShowUserDropdown(true)}
          onMouseLeave={() => setShowUserDropdown(false)}
        >
          <FontAwesomeIcon icon={faUser} />
          <span>{props.username}</span>
          <div hidden={!showUserDropdown} className="UserNameDisplayDropdown">
            <div className="Seperator" />
            <button className="ButtonPrimary" onClick={() => props.onLogout()}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="SiteContent">
        <span className="SiteTitle">Unsere Getränkeauswahl</span>

        <div className="InnerContent">
          <div className="ProdcutTilesList">{renderProductTiles()}</div>
        </div>
      </div>
      <div className="Footer">
        <span>
          <Link className="Impressum" to={"/impressum"}>Impressum</Link>
        </span>

        <span>
          <Link className="Dsgvo" to={"/dsgvo"}>Datenschutzerklärung</Link>
        </span>
      </div>
    </div>
  );
}

export default OverviewSite;
