import React from "react";
import "./AdminProductTile.scss";
import { TProduct } from "../productTile/productTile";
import useProducts from "../../hooks/UseProducts";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";

export type TAdminProductTileProps = {
  product: TProduct;
};

function AdminProductTile(props: TAdminProductTileProps) {
  const { productChangingPending, changeProduct } = useProducts(false);

//   React.useEffect(() => {
//     if (productChangingPending === false) props.onOrderChange(props.order);
//   }, [productChangingPending]);

const onProductInStockClick = (product: TProduct): void => {
    product.inStock = true;
    changeProduct(product);
  };

  const onProductNotInStockClick = (product: TProduct): void => {
    product.inStock = false;
    changeProduct(product);
  };

  return (
    <div className="AdminProductTile">
      {productChangingPending ? (
        <LoadingSpinner />
      ) : (
        <>
          <span className="Id"># {props.product.id}</span>
          <img src={props.product.picture} />
          {/* <hr className="SeperatorVertical" /> */}
          <span className="Title">{props.product.title}</span>
          <hr className="SeperatorVertical" />
          <span className="Description">{props.product.description}</span>
          <hr className="SeperatorVertical" />
          <span className="Title">Verfügbar: {props.product.inStock.toString()}</span>
          <span className="Actions">
            <button
              className="ButtonPrimary error"
              disabled={props.product.inStock === false}
              onClick={() => onProductNotInStockClick(props.product)}
            >
              <FontAwesomeIcon icon={faCancel} className="MessageBarIcon" />
            </button>
            <button
              className="ButtonPrimary success"
              disabled={props.product.inStock === true}
              onClick={() => onProductInStockClick(props.product)}
            >
              <FontAwesomeIcon icon={faCheck} className="MessageBarIcon" />
            </button>
          </span>
        </>
      )}
    </div>
  );
}

export default AdminProductTile;
