import React from 'react';
import './productTile.scss';

export type TProduct = {
    id: number;
    title: string;
    description: string;
    ingredients: TIngredient[];
    canOrdered: boolean;
    inStock: boolean;
    picture: string; // base64 string
}

export type TIngredient = {
    id: number;
    name: string;
}

export type TProductTileProps = {
    product: TProduct;
    onClick: () => void;
}

function ProductTile(props: TProductTileProps) {

    return (
        <div className="ProductTile" onClick={props.onClick}>
            <img src={props.product.picture} />
            <span className='Title'>{props.product.title}</span>
            <span className='Description'>{props.product.description}</span>
        </div>
    );
}

export default ProductTile;
